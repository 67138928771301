import _utils from "../../utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const utils = _utils;

class EntryTransformer {
  constructor(_settings) {
    this._settings = _settings;
  }

  getTransformer() {
    return entry => this._transform(entry);
  }

  _transform(entry) {
    let filepath = entry.path;

    if (this._settings.absolute) {
      filepath = utils.path.makeAbsolute(this._settings.cwd, filepath);
      filepath = utils.path.unixify(filepath);
    }

    if (this._settings.markDirectories && entry.dirent.isDirectory()) {
      filepath += "/";
    }

    if (!this._settings.objectMode) {
      return filepath;
    }

    return Object.assign(Object.assign({}, entry), {
      path: filepath
    });
  }

}

exports.default = EntryTransformer;
export default exports;