import _matcher from "./matcher";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const matcher_1 = _matcher;

class PartialMatcher extends matcher_1.default {
  match(filepath) {
    const parts = filepath.split("/");
    const levels = parts.length;

    const patterns = this._storage.filter(info => !info.complete || info.segments.length > levels);

    for (const pattern of patterns) {
      const section = pattern.sections[0];
      /**
       * In this case, the pattern has a globstar and we must read all directories unconditionally,
       * but only if the level has reached the end of the first group.
       *
       * fixtures/{a,b}/**
       *  ^ true/false  ^ always true
      */

      if (!pattern.complete && levels > section.length) {
        return true;
      }

      const match = parts.every((part, index) => {
        const segment = pattern.segments[index];

        if (segment.dynamic && segment.patternRe.test(part)) {
          return true;
        }

        if (!segment.dynamic && segment.pattern === part) {
          return true;
        }

        return false;
      });

      if (match) {
        return true;
      }
    }

    return false;
  }

}

exports.default = PartialMatcher;
export default exports;