import _utils from "../../utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const utils = _utils;

class EntryFilter {
  constructor(_settings, _micromatchOptions) {
    this._settings = _settings;
    this._micromatchOptions = _micromatchOptions;
    this.index = new Map();
  }

  getFilter(positive, negative) {
    const positiveRe = utils.pattern.convertPatternsToRe(positive, this._micromatchOptions);
    const negativeRe = utils.pattern.convertPatternsToRe(negative, this._micromatchOptions);
    return entry => this._filter(entry, positiveRe, negativeRe);
  }

  _filter(entry, positiveRe, negativeRe) {
    if (this._settings.unique && this._isDuplicateEntry(entry)) {
      return false;
    }

    if (this._onlyFileFilter(entry) || this._onlyDirectoryFilter(entry)) {
      return false;
    }

    if (this._isSkippedByAbsoluteNegativePatterns(entry.path, negativeRe)) {
      return false;
    }

    const filepath = this._settings.baseNameMatch ? entry.name : entry.path;
    const isMatched = this._isMatchToPatterns(filepath, positiveRe) && !this._isMatchToPatterns(entry.path, negativeRe);

    if (this._settings.unique && isMatched) {
      this._createIndexRecord(entry);
    }

    return isMatched;
  }

  _isDuplicateEntry(entry) {
    return this.index.has(entry.path);
  }

  _createIndexRecord(entry) {
    this.index.set(entry.path, undefined);
  }

  _onlyFileFilter(entry) {
    return this._settings.onlyFiles && !entry.dirent.isFile();
  }

  _onlyDirectoryFilter(entry) {
    return this._settings.onlyDirectories && !entry.dirent.isDirectory();
  }

  _isSkippedByAbsoluteNegativePatterns(entryPath, patternsRe) {
    if (!this._settings.absolute) {
      return false;
    }

    const fullpath = utils.path.makeAbsolute(this._settings.cwd, entryPath);
    return utils.pattern.matchAny(fullpath, patternsRe);
  }
  /**
   * First, just trying to apply patterns to the path.
   * Second, trying to apply patterns to the path with final slash.
   */


  _isMatchToPatterns(entryPath, patternsRe) {
    const filepath = utils.path.removeLeadingDotSegment(entryPath);
    return utils.pattern.matchAny(filepath, patternsRe) || utils.pattern.matchAny(filepath + "/", patternsRe);
  }

}

exports.default = EntryFilter;
export default exports;