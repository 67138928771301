import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeLeadingDotSegment = exports.escape = exports.makeAbsolute = exports.unixify = void 0;
const path = _path;
const LEADING_DOT_SEGMENT_CHARACTERS_COUNT = 2; // ./ or .\\

const UNESCAPED_GLOB_SYMBOLS_RE = /(\\?)([()*?[\]{|}]|^!|[!+@](?=\())/g;
/**
 * Designed to work only with simple paths: `dir\\file`.
 */

function unixify(filepath) {
  return filepath.replace(/\\/g, "/");
}

exports.unixify = unixify;

function makeAbsolute(cwd, filepath) {
  return path.resolve(cwd, filepath);
}

exports.makeAbsolute = makeAbsolute;

function escape(pattern) {
  return pattern.replace(UNESCAPED_GLOB_SYMBOLS_RE, "\\$2");
}

exports.escape = escape;

function removeLeadingDotSegment(entry) {
  // We do not use `startsWith` because this is 10x slower than current implementation for some cases.
  // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
  if (entry.charAt(0) === ".") {
    const secondCharactery = entry.charAt(1);

    if (secondCharactery === "/" || secondCharactery === "\\") {
      return entry.slice(LEADING_DOT_SEGMENT_CHARACTERS_COUNT);
    }
  }

  return entry;
}

exports.removeLeadingDotSegment = removeLeadingDotSegment;
export default exports;