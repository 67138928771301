import _stream from "../readers/stream";
import _provider from "./provider";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const stream_1 = _stream;
const provider_1 = _provider;

class ProviderAsync extends provider_1.default {
  constructor() {
    super(...arguments);
    this._reader = new stream_1.default(this._settings);
  }

  read(task) {
    const root = this._getRootDirectory(task);

    const options = this._getReaderOptions(task);

    const entries = [];
    return new Promise((resolve, reject) => {
      const stream = this.api(root, task, options);
      stream.once("error", reject);
      stream.on("data", entry => entries.push(options.transform(entry)));
      stream.once("end", () => resolve(entries));
    });
  }

  api(root, task, options) {
    if (task.dynamic) {
      return this._reader.dynamic(root, options);
    }

    return this._reader.static(task.patterns, options);
  }

}

exports.default = ProviderAsync;
export default exports;