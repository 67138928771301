import _utils from "../../utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const utils = _utils;

class ErrorFilter {
  constructor(_settings) {
    this._settings = _settings;
  }

  getFilter() {
    return error => this._isNonFatalError(error);
  }

  _isNonFatalError(error) {
    return utils.errno.isEnoentCodeError(error) || this._settings.suppressErrors;
  }

}

exports.default = ErrorFilter;
export default exports;