import * as _fs2 from "@nodelib/fs.stat";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _fs4 from "@nodelib/fs.walk";

var _fs3 = "default" in _fs4 ? _fs4.default : _fs4;

import _reader from "./reader";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const fsStat = _fs;
const fsWalk = _fs3;
const reader_1 = _reader;

class ReaderSync extends reader_1.default {
  constructor() {
    super(...arguments);
    this._walkSync = fsWalk.walkSync;
    this._statSync = fsStat.statSync;
  }

  dynamic(root, options) {
    return this._walkSync(root, options);
  }

  static(patterns, options) {
    const entries = [];

    for (const pattern of patterns) {
      const filepath = this._getFullEntryPath(pattern);

      const entry = this._getEntry(filepath, pattern, options);

      if (entry === null || !options.entryFilter(entry)) {
        continue;
      }

      entries.push(entry);
    }

    return entries;
  }

  _getEntry(filepath, pattern, options) {
    try {
      const stats = this._getStat(filepath);

      return this._makeEntry(stats, pattern);
    } catch (error) {
      if (options.errorFilter(error)) {
        return null;
      }

      throw error;
    }
  }

  _getStat(filepath) {
    return this._statSync(filepath, this._fsStatSettings);
  }

}

exports.default = ReaderSync;
export default exports;