import _utils from "../../utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const utils = _utils;

class Matcher {
  constructor(_patterns, _settings, _micromatchOptions) {
    this._patterns = _patterns;
    this._settings = _settings;
    this._micromatchOptions = _micromatchOptions;
    this._storage = [];

    this._fillStorage();
  }

  _fillStorage() {
    /**
     * The original pattern may include `{,*,**,a/*}`, which will lead to problems with matching (unresolved level).
     * So, before expand patterns with brace expansion into separated patterns.
     */
    const patterns = utils.pattern.expandPatternsWithBraceExpansion(this._patterns);

    for (const pattern of patterns) {
      const segments = this._getPatternSegments(pattern);

      const sections = this._splitSegmentsIntoSections(segments);

      this._storage.push({
        complete: sections.length <= 1,
        pattern,
        segments,
        sections
      });
    }
  }

  _getPatternSegments(pattern) {
    const parts = utils.pattern.getPatternParts(pattern, this._micromatchOptions);
    return parts.map(part => {
      const dynamic = utils.pattern.isDynamicPattern(part, this._settings);

      if (!dynamic) {
        return {
          dynamic: false,
          pattern: part
        };
      }

      return {
        dynamic: true,
        pattern: part,
        patternRe: utils.pattern.makeRe(part, this._micromatchOptions)
      };
    });
  }

  _splitSegmentsIntoSections(segments) {
    return utils.array.splitWhen(segments, segment => segment.dynamic && utils.pattern.hasGlobStar(segment.pattern));
  }

}

exports.default = Matcher;
export default exports;