import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _fs2 from "@nodelib/fs.stat";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import _utils from "../utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const path = _path;
const fsStat = _fs;
const utils = _utils;

class Reader {
  constructor(_settings) {
    this._settings = _settings;
    this._fsStatSettings = new fsStat.Settings({
      followSymbolicLink: this._settings.followSymbolicLinks,
      fs: this._settings.fs,
      throwErrorOnBrokenSymbolicLink: this._settings.followSymbolicLinks
    });
  }

  _getFullEntryPath(filepath) {
    return path.resolve(this._settings.cwd, filepath);
  }

  _makeEntry(stats, pattern) {
    const entry = {
      name: pattern,
      path: pattern,
      dirent: utils.fs.createDirentFromStats(pattern, stats)
    };

    if (this._settings.stats) {
      entry.stats = stats;
    }

    return entry;
  }

  _isFatalError(error) {
    return !utils.errno.isEnoentCodeError(error) && !this._settings.suppressErrors;
  }

}

exports.default = Reader;
export default exports;