var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isEnoentCodeError = void 0;

function isEnoentCodeError(error) {
  return error.code === "ENOENT";
}

exports.isEnoentCodeError = isEnoentCodeError;
export default exports;