import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _os2 from "os";

var _os = "default" in _os2 ? _os2.default : _os2;

import _process from "process";
var exports = {};
var process = _process;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_FILE_SYSTEM_ADAPTER = void 0;
const fs = _fs;
const os = _os;
/**
 * The `os.cpus` method can return zero. We expect the number of cores to be greater than zero.
 * https://github.com/nodejs/node/blob/7faeddf23a98c53896f8b574a6e66589e8fb1eb8/lib/os.js#L106-L107
 */

const CPU_COUNT = Math.max(os.cpus().length, 1);
exports.DEFAULT_FILE_SYSTEM_ADAPTER = {
  lstat: fs.lstat,
  lstatSync: fs.lstatSync,
  stat: fs.stat,
  statSync: fs.statSync,
  readdir: fs.readdir,
  readdirSync: fs.readdirSync
};

class Settings {
  constructor(_options = {}) {
    this._options = _options;
    this.absolute = this._getValue(this._options.absolute, false);
    this.baseNameMatch = this._getValue(this._options.baseNameMatch, false);
    this.braceExpansion = this._getValue(this._options.braceExpansion, true);
    this.caseSensitiveMatch = this._getValue(this._options.caseSensitiveMatch, true);
    this.concurrency = this._getValue(this._options.concurrency, CPU_COUNT);
    this.cwd = this._getValue(this._options.cwd, process.cwd());
    this.deep = this._getValue(this._options.deep, Infinity);
    this.dot = this._getValue(this._options.dot, false);
    this.extglob = this._getValue(this._options.extglob, true);
    this.followSymbolicLinks = this._getValue(this._options.followSymbolicLinks, true);
    this.fs = this._getFileSystemMethods(this._options.fs);
    this.globstar = this._getValue(this._options.globstar, true);
    this.ignore = this._getValue(this._options.ignore, []);
    this.markDirectories = this._getValue(this._options.markDirectories, false);
    this.objectMode = this._getValue(this._options.objectMode, false);
    this.onlyDirectories = this._getValue(this._options.onlyDirectories, false);
    this.onlyFiles = this._getValue(this._options.onlyFiles, true);
    this.stats = this._getValue(this._options.stats, false);
    this.suppressErrors = this._getValue(this._options.suppressErrors, false);
    this.throwErrorOnBrokenSymbolicLink = this._getValue(this._options.throwErrorOnBrokenSymbolicLink, false);
    this.unique = this._getValue(this._options.unique, true);

    if (this.onlyDirectories) {
      this.onlyFiles = false;
    }

    if (this.stats) {
      this.objectMode = true;
    }
  }

  _getValue(option, value) {
    return option === undefined ? value : option;
  }

  _getFileSystemMethods(methods = {}) {
    return Object.assign(Object.assign({}, exports.DEFAULT_FILE_SYSTEM_ADAPTER), methods);
  }

}

exports.default = Settings;
export default exports;