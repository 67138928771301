import * as _stream2 from "stream";

var _stream = "default" in _stream2 ? _stream2.default : _stream2;

import _stream3 from "../readers/stream";
import _provider from "./provider";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const stream_1 = _stream;
const stream_2 = _stream3;
const provider_1 = _provider;

class ProviderStream extends provider_1.default {
  constructor() {
    super(...arguments);
    this._reader = new stream_2.default(this._settings);
  }

  read(task) {
    const root = this._getRootDirectory(task);

    const options = this._getReaderOptions(task);

    const source = this.api(root, task, options);
    const destination = new stream_1.Readable({
      objectMode: true,
      read: () => {}
    });
    source.once("error", error => destination.emit("error", error)).on("data", entry => destination.emit("data", options.transform(entry))).once("end", () => destination.emit("end"));
    destination.once("close", () => source.destroy());
    return destination;
  }

  api(root, task, options) {
    if (task.dynamic) {
      return this._reader.dynamic(root, options);
    }

    return this._reader.static(task.patterns, options);
  }

}

exports.default = ProviderStream;
export default exports;